import { useEffect, useState } from 'react';
import { isServer } from '../../../play-core/utils/ssr-helper';
const getScrollPosition = () => isServer ? {
  x: 0,
  y: 0
} : {
  x: window.pageXOffset,
  y: window.pageYOffset
};

// no debounce yet
const useScrollPosition = () => {
  const [position, setScrollPosition] = useState(getScrollPosition());
  useEffect(() => {
    if (isServer) {
      return;
    }
    let requestRunning = null;
    const handleScroll = () => {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return position;
};
export const useScrollYPosition = () => {
  const {
    y
  } = useScrollPosition();
  return y;
};
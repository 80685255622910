import { createRequestUrl } from '../../../play-core/utils/request-url-helper';
export const TvProgramGuideApiPath = '/tv-program-guide';
export const loadTvProgramGuide = async (date, axios, businessUnits, reduced, channelId) => {
  const requestUrl = createRequestUrl(TvProgramGuideApiPath, {
    date,
    businessUnits,
    reduced,
    channelId
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};
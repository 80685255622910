import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Viewports } from '../../../../../play-core/styles/Viewports';
import { TransparentButton } from '../../../components/ClickableComponents/Button';
import { headerHeight } from '../../../components/Header/Header.globals';
import { desktopLogoWidth } from '../../../components/Header/MainNavigation/PlayLogo';
import { defaultContainerPadding, getPlayContainerSidePaddingStyle } from '../../../components/PlayContainer/PlayContainer';
export const mobileSearchHeaderHeight = 84;
export const searchBarSideMargin = desktopLogoWidth + 30;
export const Container = styled.div.withConfig({
  displayName: "SearchBarstyles__Container",
  componentId: "sc-1r2ddho-0"
})(["display:flex;align-items:center;justify-content:space-between;background-color:", ";position:absolute;right:0;top:0;left:0;", ";", ";", "{transform:", ";}", "{transform:", ";position:fixed;transition:transform 200ms ease-in;min-height:", "px;}", "{margin-left:", "px;}"], Colors.DARK_202020, headerHeight, getPlayContainerSidePaddingStyle(defaultContainerPadding), Viewports.for('mediumUp'), props => `translateY(${props.isOpen ? 0 : '-200%'})`, Viewports.for('small'), props => `translateY(${props.isOpen ? 0 : '-100%'})`, mobileSearchHeaderHeight, Viewports.for('mediumUp'), searchBarSideMargin);
export const MobileCloseButton = styled.button.withConfig({
  displayName: "SearchBarstyles__MobileCloseButton",
  componentId: "sc-1r2ddho-1"
})(["padding:0 10px 0 5px;", "{display:none;}"], Viewports.for('mediumUp'));
export const CloseButton = styled(TransparentButton).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "SearchBarstyles__CloseButton",
  componentId: "sc-1r2ddho-2"
})(["margin-left:20px;", "{display:none;}"], Viewports.for('small'));
import { useTranslation } from 'react-i18next';
import { usePath, useVideoConfig } from '../../../config/VideoAppContext';
import { usePersonalizationSettings } from '../../../providers/DataProvider/DataContext';
export const usePrependMenuEntries = () => {
  const {
    myVideosSupported
  } = useVideoConfig();
  const {
    isActive: isPersonalizationActivate
  } = usePersonalizationSettings();
  const {
    myVideoPath
  } = usePath();
  const {
    t
  } = useTranslation();
  const showMyVideos = isPersonalizationActivate && myVideosSupported;
  let prependingMenuEntries = [];
  if (showMyVideos) {
    prependingMenuEntries = [...prependingMenuEntries, {
      type: 'Link',
      title: t('myVideos.title'),
      url: myVideoPath(),
      icon: 'Playlist'
    }, {
      type: 'Divider'
    }];
  }
  return prependingMenuEntries;
};
import { useCallback } from 'react';
import { usePrivacyDispatch } from './PrivacyProvider/PrivacyContext';
import { useUdpConsenteventTracking } from '../../src/analytics/use-udp-consent-event-tracking';
import { personalizationConsentStorage } from '../storage/personalization-consent-storage';
import { udpAnalyticsIdStorage } from '../storage/udp-analytics-id-storage';
export const usePersonalizationConsentTracking = () => {
  const trackConsentEvent = useUdpConsenteventTracking();
  const dispatch = usePrivacyDispatch();
  return useCallback(consentGiven => {
    const personalizationConsent = personalizationConsentStorage.get() ?? false;
    if (!consentGiven && personalizationConsent) {
      dispatch({
        type: 'set-analytics-id',
        analyticsId: udpAnalyticsIdStorage.reset()
      });
    }
    personalizationConsentStorage.set(consentGiven);
    trackConsentEvent(consentGiven);
  }, [trackConsentEvent, dispatch]);
};
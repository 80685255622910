import { useParams } from "@remix-run/react";
import { toSeoString } from '../../../play-core/utils/to-seo-string';
export const usePacPageId = (route, initialData) => {
  const {
    topicName,
    pageId: id
  } = useParams();
  switch (route.pageId) {
    case 'home':
      return initialData.pacPageConfigs?.landingPage.id;
    case 'micropage':
      if (id) {
        return id;
      }
      break;
    case 'topic':
      {
        const topic = initialData.topics?.find(t => toSeoString(t.title) === toSeoString(topicName));
        const topicPage = topic && initialData.pacPageConfigs?.topicPages[topic.urn];
        return topicPage?.id;
      }
    default:
  }
};
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { pageViewTrackingDelay } from './analytics.config';
import { srfModuleParameterStorage } from './srf-module-parameter';
import { isBrowser } from '../../../play-core/utils/ssr-helper';
import { usePlayConfig } from '../config/PlayConfigContext';
export const useTCLoader = ({
  route,
  nonce
}) => {
  const config = usePlayConfig();
  const [currentPath, setCurrentPath] = useState();
  const [tcScript, setTCScript] = useState();
  const trackPageView = useDebouncedCallback(() => {
    setGlobalTcVars(config, route);
    if (isBrowser) {
      removeScriptElement(tcScript);
      const script = document.createElement('script');
      script.src = 'https://colibri-js.akamaized.net/penguin/tc_SRGGD_31.js';
      script.async = true;
      script.nonce = nonce;
      document.head.appendChild(script);
      setTCScript(script);
      setCurrentPath(window.location.pathname);
    }
  }, pageViewTrackingDelay);
  useEffect(() => {
    if (window.location.pathname !== currentPath) {
      trackPageView();
    }
    return () => removeScriptElement(tcScript);
  }, [trackPageView, currentPath, route, tcScript]);
};
const setGlobalTcVars = (config, route) => {
  const {
    businessUnit,
    language,
    serverEnvironment,
    isRequestedViaCDN
  } = config;
  let srgModuleParameter = {};
  if (businessUnit === 'srf') {
    const params = srfModuleParameterStorage.get();
    srgModuleParameter = {
      srg_mod1: params.srg_override_mod1 ?? params.srg_mod1 ?? '',
      srg_mod2: params.srg_override_mod2 ?? params.srg_mod2 ?? '',
      srg_mod3: params.srg_override_mod3 ?? params.srg_mod3 ?? '',
      srg_mod4: params.srg_override_mod4 ?? params.srg_mod4 ?? ''
    };
    srfModuleParameterStorage.remove();
  }
  const hrefParts = window.location.pathname.split('/');
  const playIndex = hrefParts.findIndex(href => href.toLowerCase() === 'play');
  Object.keys(window.tc_vars || {}).forEach(key => {
    if (typeof key === 'string' && key.startsWith('navigation_level_')) {
      delete window.tc_vars?.[key];
    }
  });
  const navigationLevels = hrefParts.filter((href, index) => index >= playIndex && !!href).reduce((previousValue, currentValue, index) => ({
    ...previousValue,
    [`navigation_level_${index + 1}`]: currentValue.toLowerCase()
  }), {});
  window.tc_vars = {
    ...window.tc_vars,
    content_bu_owner: businessUnit.toUpperCase(),
    content_language: languageMapping[language],
    navigation_environment: getNavigationEnvironment(serverEnvironment, isRequestedViaCDN),
    navigation_app_site_name: appNameMapping[businessUnit],
    page_id: `web.${businessUnit}.Play.${route.pageSection.toUpperCase()}.${route.pageId}`,
    content_title: route.pageId,
    content_page_type: route.pageType,
    navigation_property_type: 'web',
    content_character_encoding: 'UTF-8',
    url: window.location.href,
    ...navigationLevels,
    ...srgModuleParameter
  };
};
const removeScriptElement = script => {
  if (script && document.head.contains(script)) {
    try {
      document.head.removeChild(script);
    } catch (_) {
      // ignore
    }
  }
};
const languageMapping = {
  de: 'ger',
  fr: 'fre',
  it: 'ita',
  rm: 'roh',
  en: 'eng'
};
const getNavigationEnvironment = (serverEnvironment, isRequestedViaCDN) => {
  if (isRequestedViaCDN && serverEnvironment === 'production') {
    return 'prod';
  }
  return 'preprod';
};
const appNameMapping = {
  srf: 'srf.ch',
  rts: 'rts.ch',
  rsi: 'rsi.ch',
  rtr: 'rtr.ch',
  swi: 'swissinfo.ch'
};
import { styled } from 'styled-components';
import { Colors } from '../../../../play-core/styles/Colors';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../play-core/styles/Viewports';
import { PlayContainer } from '../PlayContainer/PlayContainer';
export const SrgPlaySection = styled.section.withConfig({
  displayName: "Footerstyles__SrgPlaySection",
  componentId: "sc-1wzd3dc-0"
})(["margin-top:8px;margin-bottom:24px;display:flex;align-items:flex-end;align-content:space-between;flex-wrap:wrap;"]);
export const StyledFooter = styled(PlayContainer).withConfig({
  displayName: "Footerstyles__StyledFooter",
  componentId: "sc-1wzd3dc-1"
})(["display:flex;margin-top:auto;border-top:70px solid transparent;background-color:", ";background-clip:padding-box;"], Colors.DARK_202020);
export const FooterLinks = styled.div.withConfig({
  displayName: "Footerstyles__FooterLinks",
  componentId: "sc-1wzd3dc-2"
})(["margin-left:-12px;padding-top:8px;padding-bottom:8px;display:flex;flex-wrap:wrap;", "{display:grid;grid-template-columns:50% 50%;> a{text-wrap:wrap;}}"], Viewports.for('small'));
export const SRGSSRImage = styled.img.withConfig({
  displayName: "Footerstyles__SRGSSRImage",
  componentId: "sc-1wzd3dc-3"
})(["height:16px;"]);
export const CompanyDescription = styled.span.withConfig({
  displayName: "Footerstyles__CompanyDescription",
  componentId: "sc-1wzd3dc-4"
})(["margin-top:8px;color:", ";line-height:16px;", ";"], Colors.GREY_969696, Fonts.regular(12));
export const PlayBelonging = styled.a.withConfig({
  displayName: "Footerstyles__PlayBelonging",
  componentId: "sc-1wzd3dc-5"
})(["display:flex;flex-direction:column;align-items:flex-start;"]);
export const BuPlayList = styled.div.withConfig({
  displayName: "Footerstyles__BuPlayList",
  componentId: "sc-1wzd3dc-6"
})(["display:flex;flex-wrap:wrap;margin-left:auto;margin-top:8px;> *{margin-top:8px;margin-right:8px;&:last-child{margin-right:0;}}"]);
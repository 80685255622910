import { styled } from 'styled-components';
import { Colors, hexToRGB } from '../../../../play-core/styles/Colors';
import { Button } from '../ClickableComponents/Button';
export const MenuContainer = styled.div.withConfig({
  displayName: "Menustyles__MenuContainer",
  componentId: "sc-dkfr66-0"
})(["position:relative;display:inline-block;"]);
export const MenuOpenButton = styled(Button).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "Menustyles__MenuOpenButton",
  componentId: "sc-dkfr66-1"
})(["padding-left:8px;padding-right:8px;"]);
export const MenuContent = styled.div.withConfig({
  displayName: "Menustyles__MenuContent",
  componentId: "sc-dkfr66-2"
})(["top:100%;right:0;position:absolute;z-index:1;padding:8px;margin-top:2px;border-radius:4px;background-color:", ";box-shadow:0 2px 4px 0 ", ";display:inline-flex;flex-direction:column;width:max-content;"], Colors.GREY_3a3a3a, hexToRGB(Colors.BLACK, 0.5));
export const MenuDivider = styled.div.withConfig({
  displayName: "Menustyles__MenuDivider",
  componentId: "sc-dkfr66-3"
})(["border-bottom:1px solid ", ";margin:2px -8px;"], Colors.GREY_515151);
import { styled } from 'styled-components';
import { Colors } from '../../../../play-core/styles/Colors';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../play-core/styles/Viewports';
export const Layout = styled.div.withConfig({
  displayName: "ErrorPagestyles__Layout",
  componentId: "sc-18rr474-0"
})(["width:100%;display:flex;flex-direction:column;align-items:center;"]);
export const Title = styled.h1.withConfig({
  displayName: "ErrorPagestyles__Title",
  componentId: "sc-18rr474-1"
})(["", ";color:", ";margin:70px 0 0 0;", "{", ";}"], Fonts.medium(120), Colors.WHITE, Viewports.for('small'), Fonts.medium(60));
export const Subtitle = styled.div.withConfig({
  displayName: "ErrorPagestyles__Subtitle",
  componentId: "sc-18rr474-2"
})(["", ";line-height:48px;color:", ";", "{", ";}"], Fonts.medium(36), Colors.GREY_d2d2d2, Viewports.for('small'), Fonts.medium(24));
export const Text = styled.p.withConfig({
  displayName: "ErrorPagestyles__Text",
  componentId: "sc-18rr474-3"
})(["", ";line-height:32px;color:", ";text-align:center;margin:8px;max-width:640px;", "{", ";}"], Fonts.light(24), Colors.GREY_d2d2d2, Viewports.for('small'), Fonts.light(16));
import { createRequestUrl } from '../../../play-core/utils/request-url-helper';
export const TvProgramGuideNonSrgApiPath = '/tv-program-guide-non-srg';
export const loadTvProgramGuideNonSrg = async (date, axios, reduced, channelId) => {
  const requestUrl = createRequestUrl(TvProgramGuideNonSrgApiPath, {
    date,
    reduced,
    channelId
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};
import { styled, css } from 'styled-components';
import { Colors, hexToRGB } from '../../../../../play-core/styles/Colors';
import { Viewports } from '../../../../../play-core/styles/Viewports';
import { zIndex } from '../../../../../play-core/styles/z-index';
import { colorSets } from '../../ClickableComponents/styles';
import { PlayContainer, ContentContainer } from '../../PlayContainer/PlayContainer';
import { headerShadow, mobileHeaderHeight } from '../Header.globals';
import { useHeaderState } from '../HeaderContext';
const defaultColorSet = {
  ...colorSets.transparent,
  iconColor: Colors.WHITE,
  color: Colors.WHITE,
  hoverIconColor: Colors.WHITE,
  hoverColor: Colors.WHITE,
  activeIconColor: Colors.WHITE,
  activeColor: Colors.WHITE
};
const transparentColorSet = {
  ...defaultColorSet,
  hoverIconColor: Colors.GREY_d2d2d2,
  hoverColor: Colors.GREY_d2d2d2,
  hoverBackgroundColor: 'transparent'
};
export const useHeaderColorSet = () => {
  const {
    layout,
    hasScrolled,
    hasSecondLevelNavi
  } = useHeaderState();
  const isTransparent = layout === 'transparent' && !hasScrolled && !hasSecondLevelNavi;
  return isTransparent ? transparentColorSet : defaultColorSet;
};
const staticHeader = css(["position:inherit;background-color:", ";"], Colors.DARK_202020);
const transparentHeader = css(["position:fixed;background-image:linear-gradient(0deg,", " 0%,", " 100%);&:after{opacity:", ";transition:opacity 0.3s ease-in-out;background-color:", ";content:'';position:absolute;z-index:-1;width:100%;height:100%;", "}"], hexToRGB(Colors.GREY_1a1a1a, 0), hexToRGB(Colors.GREY_1a1a1a, 1), ({
  hasScrolled
}) => hasScrolled ? 1 : 0, Colors.DARK_202020, headerShadow);
const stickyHeader = css(["position:sticky;background-color:", ";", ""], Colors.DARK_202020, headerShadow);
export const MainNaviContainer = styled(PlayContainer).withConfig({
  displayName: "MainNavigationstyles__MainNaviContainer",
  componentId: "sc-1w0yq1x-0"
})(["top:0;left:0;z-index:", ";", ";", "{position:relative;}", "{", ";top:", ";}"], zIndex.header + 1, ({
  hasSecondLevelNavi,
  showTransparent
}) =>
// The transparent header is never shown if there is a second level navigation
hasSecondLevelNavi ? staticHeader : showTransparent ? transparentHeader : stickyHeader, ContentContainer, Viewports.for('small'), ({
  hasSecondLevelNavi
}) =>
// The transparent header is never shown on  sticky on small screens
hasSecondLevelNavi ? staticHeader : stickyHeader, ({
  showMainNavi,
  showMainNaviOnSmallScreens
}) => showMainNavi && showMainNaviOnSmallScreens ? `-${mobileHeaderHeight}px` : 0);
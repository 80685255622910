import { useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
import { pageViewTrackingDelay } from './analytics.config';
import { useClientSession } from './AnalyticsContext';
import { srfModuleParameterStorage } from './srf-module-parameter';
import { usePacPageId } from './use-pac-page-id';
import { useUdpTracking } from './use-udp-tracking';
import { isBrowser } from '../../../play-core/utils/ssr-helper';
import { useVideoConfig } from '../config/VideoAppContext';
import { useAnalyticsId } from '../privacy/PrivacyProvider/PrivacyContext';
import { userGroupStorage } from '../storage/user-group-storage';
export const useUdpPageViewTracking = (route, initialData) => {
  const srgModsRef = useRef(srfModuleParameterStorage.get());
  const {
    businessUnit,
    language
  } = useVideoConfig();
  const prevPathName = usePrevious(isBrowser ? window.location.pathname : '');
  const pacPageId = usePacPageId(route, initialData);
  const track = useUdpTracking('pageview');
  const {
    groupNumber: abTestGroup
  } = userGroupStorage.get();
  const timestamp = Date.now();
  const analyticsId = useAnalyticsId();
  const {
    id: sessionId,
    timestamp: sessionTimestamp
  } = useClientSession();

  // delay post to ignore redirects & make sure all props are up-to-date like document.title
  const trackPage = useDebouncedCallback(data => {
    const payload = {
      ...data,
      schema_version: '0.1.0',
      type: 'page_view',
      timestamp,
      pac_page_id: pacPageId,
      content_page_type: route.pageId,
      content_title_pretty: document.title,
      url: window.location.href,
      referrer: document.referrer,
      user_agent: window.navigator.userAgent,
      screen_resolution: `${window.screen.availWidth}x${window.screen.availHeight}`,
      language: window.navigator.language,
      ab_test_group: abTestGroup,
      application_type: 'spa'
    };
    const payloadV2 = {
      schema_version: '0.2.0',
      type: 'page_view',
      timestamp,
      page_id: pacPageId ?? route.pageId,
      url: window.location.href,
      referrer: document.referrer,
      product: 'web',
      backend_system: 'playnext',
      business_unit: 'gd',
      platform: 'web',
      app_version: window.playVersion.commitSha,
      cookie_id: analyticsId,
      language,
      client_session_id: sessionId,
      client_session_timestamp: sessionTimestamp,
      user_agent: window.navigator.userAgent,
      screen_resolution: `${window.screen.availWidth}x${window.screen.availHeight}`,
      viewport_size: `${window.innerWidth}x${window.innerHeight}`,
      screen_orientation: window.screen.orientation.type,
      screen_density: window.devicePixelRatio
    };
    track(payload);
    track(payloadV2);
  }, pageViewTrackingDelay);
  useEffect(() => {
    const {
      current: params = {}
    } = srgModsRef;
    const srgModuleParameter = {
      srg_mod1: params.srg_override_mod1 ?? params.srg_mod1 ?? '',
      srg_mod2: params.srg_override_mod2 ?? params.srg_mod2 ?? '',
      srg_mod3: params.srg_override_mod3 ?? params.srg_mod3 ?? '',
      srg_mod4: params.srg_override_mod4 ?? params.srg_mod4 ?? ''
    };
    if (isBrowser && window.location.pathname !== prevPathName) {
      srgModsRef.current = undefined;
      const mods = businessUnit === 'srf' ? {
        navigation_path_srg_mod_1: srgModuleParameter.srg_mod1,
        navigation_path_srg_mod_2: srgModuleParameter.srg_mod2,
        navigation_path_srg_mod_3: srgModuleParameter.srg_mod3,
        navigation_path_srg_mod_4: srgModuleParameter.srg_mod4
      } : {};
      trackPage(mods);
    }
  }, [businessUnit, prevPathName, route, trackPage]);
};
import { playLocalStorage } from '../storage/play-local-storage';
const storageKey = 'srf:analytics:mod';
const defaultValue = {};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem();
};
const remove = () => {
  const {
    removeItem
  } = playLocalStorage(storageKey, defaultValue);
  removeItem();
};
export const srfModuleParameterStorage = {
  get,
  remove
};
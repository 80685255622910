import { useCallback, useMemo } from 'react';
import { useVideoConfig } from '../config/VideoAppContext';
import { useAnalyticsId } from '../privacy/PrivacyProvider/PrivacyContext';
export const useUdpTracking = urlExtension => {
  const {
    businessUnit,
    udpTrackingBaseUrl
  } = useVideoConfig();
  const analyticsId = useAnalyticsId();
  const coreData = useMemo(() => ({
    product: 'web',
    backend_system: 'play',
    business_unit: businessUnit
  }), [businessUnit]);
  return useCallback(payload => {
    if (analyticsId) {
      fetch(`${udpTrackingBaseUrl}/${urlExtension}`, {
        method: 'POST',
        body: JSON.stringify({
          ...coreData,
          ...payload,
          cookie_id: analyticsId
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch(() => {});
    }
  }, [udpTrackingBaseUrl, analyticsId, coreData, urlExtension]);
};
import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-app-promotion-banner';
const defaultValue = {
  showBanner: true
};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem().showBanner;
};
const set = showBanner => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem({
    showBanner
  });
};
export const appPromotionBannerStorage = {
  get,
  set
};
import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-personalizationConsent';
const defaultValue = {};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem().consent;
};
const set = consent => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem({
    consent
  }).consent;
};
export const personalizationConsentStorage = {
  get,
  set
};
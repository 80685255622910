import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-use/lib/misc/util';
import { useInitialRender } from '../../../../play-core/hooks/use-initial-render';
import { useVideoConfig } from '../../config/VideoAppContext';
import { appPromotionBannerStorage } from '../../storage/promotion-banner-storage';
import { isAndroid } from '../../utils/device-helper';
export const useAndroidBanner = () => {
  const isInitialRender = useInitialRender();
  const [isAppInstalled, setIsAppInstalled] = useState();
  const {
    t
  } = useTranslation();
  const {
    apps,
    businessUnit
  } = useVideoConfig();
  const androidAppRelation = apps?.android;
  const openAppLink = `play${businessUnit}://home`;
  const [isCloseButtonPressed, setIsCloseButtonPressed] = useState(false);
  const showBanner = isAppInstalled !== undefined && !isInitialRender && isBrowser && androidAppRelation?.showAppBanner && isAndroid && appPromotionBannerStorage.get() && !isCloseButtonPressed;
  useEffect(() => {
    if (isBrowser && window.navigator.getInstalledRelatedApps && window.self === window.top) {
      window.navigator.getInstalledRelatedApps().then(relatedApps => {
        const installedApp = relatedApps.some(relatedApp => relatedApp.id === androidAppRelation?.id);
        setIsAppInstalled(installedApp);
      });
    }
  }, [androidAppRelation?.id]);
  if (!showBanner) {
    return;
  }
  const appOptions = {
    install: {
      link: androidAppRelation.playStoreLink,
      text: t('appbanner.install'),
      descriptionText: t('appbanner.onPlay'),
      onClose: () => {
        appPromotionBannerStorage.set(false);
        setIsCloseButtonPressed(true);
      }
    },
    open: {
      link: openAppLink,
      text: t('appbanner.open'),
      descriptionText: t('appbanner.inApp'),
      onClose: () => {
        appPromotionBannerStorage.set(false);
        setIsCloseButtonPressed(true);
      }
    }
  };
  return isAppInstalled ? appOptions.open : appOptions.install;
};
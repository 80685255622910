import { useCallback } from 'react';
import { useUdpTracking } from './use-udp-tracking';
import { useVideoConfig } from '../config/VideoAppContext';
export const useUdpConsenteventTracking = () => {
  const {
    supportUdpConsentEventTracking
  } = useVideoConfig();
  const track = useUdpTracking('consentevent');
  return useCallback(consentGiven => {
    if (supportUdpConsentEventTracking) {
      track({
        consent: consentGiven,
        publication_at: new Date().toISOString(),
        schema_version: '1.0',
        type: 'personalization'
      });
    }
  }, [track, supportUdpConsentEventTracking]);
};
import { Link } from "@remix-run/react";
import { styled, css } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { Shadows } from '../../../../../play-core/styles/Shadows';
import { Viewports } from '../../../../../play-core/styles/Viewports';
export const BackgroundImage = styled.div.withConfig({
  displayName: "TopicTeaserstyles__BackgroundImage",
  componentId: "sc-13ia84c-0"
})(["opacity:1;top:0;left:0;bottom:0;right:0;overflow:hidden;height:100%;border-radius:6px;position:absolute;transition:opacity 200ms ease-in-out;visibility:", ";"], ({
  showTeaserImage
}) => showTeaserImage ? 'visible' : 'hidden');
export const StyledTopicTeaser = styled.div.withConfig({
  displayName: "TopicTeaserstyles__StyledTopicTeaser",
  componentId: "sc-13ia84c-1"
})(["background:linear-gradient(to left top,", ",", ");overflow:hidden;border-radius:6px;position:relative;box-shadow:2px 10px 5px 0 rgba(30,29,30,0.5);&:before{content:'';float:left;padding-bottom:", "%;}&:hover{", "{opacity:0.75;}}"], ({
  firstColor
}) => firstColor, ({
  secondColor
}) => secondColor, 100 / (16 / 9), BackgroundImage);
const clickableTopic = css(["", ";position:absolute;display:flex;align-items:center;padding-left:24px;padding-right:24px;color:", ";width:100%;height:100%;outline-offset:0;", ";", "{font-size:1.2vw;padding-right:24px;padding-left:24px;}@media only screen and (min-width:1600px){font-size:1vw;}@media only screen and (min-width:2600px){font-size:26px;}", "{font-size:1.3vw;padding-right:16px;padding-left:16px;}", "{font-size:2vw;padding-left:8px;padding-right:8px;}", "{font-size:4vw;padding-left:8px;padding-right:8px;}"], Fonts.bold(18), Colors.WHITE, Shadows.text(), Viewports.for('xLargeUp'), Viewports.for('large'), Viewports.for('medium'), Viewports.for('small'));
export const TopicLink = styled(Link).withConfig({
  displayName: "TopicTeaserstyles__TopicLink",
  componentId: "sc-13ia84c-2"
})(["", ";"], clickableTopic);
export const TopicDiv = styled.div.withConfig({
  displayName: "TopicTeaserstyles__TopicDiv",
  componentId: "sc-13ia84c-3"
})(["", ";cursor:pointer;"], clickableTopic);
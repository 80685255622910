export const queryKeys = {
  MostClickedShowsByTopic: topicId => `MostClickedShowsByTopic-${topicId}`,
  TrendingMediaByTopic: topicIds => `TrendingMediaByTopic-${topicIds.join(',')}`,
  ExpiringSoon: 'ExpiringSoon',
  EditorialPicks: 'EditorialPicks',
  LatestEpisodesByShows: (showUrn, pageSize) => `LatestEpisodesByShows-${showUrn}-${pageSize}`,
  ShowDetail: showId => `ShowDetail-${showId}`,
  SearchMedia: 'SearchMedia',
  VideoDetail: urn => `VideoDetail-${urn}`,
  ContinueWatching: ids => `ContinueWatching-${ids.join(',')}`,
  WatchAgain: ids => `WatchAgain-${ids.join(',')}`,
  LiveTvLivestream: 'LiveTvLivestream',
  LiveEvents: liveEventSwimlane => `LiveEvents-${JSON.stringify(liveEventSwimlane)}`,
  OnDemandEvents: 'OnDemandEvents',
  TvNowAndNext: 'TvNowAndNext',
  MostSearchedShows: 'MostSearchedShows',
  RecommendedMediaByUrn: (mediaUrn, userId) => `RecommendedMediaByUrn-${mediaUrn}-${userId}`,
  UdpRecommendedMedia: userId => `UdpRecommendedMedia-${userId}`,
  WatchLater: videoUrns => `WatchLater-${videoUrns.join(',')}`,
  FavoriteShows: ids => `FavoriteShows-${ids.join(',')}`,
  GeneralInformationMessage: 'GeneralInformationMessage',
  VideosByShowId: id => `VideosByShowId-${id}`,
  TvLivestreamsNowAndNext: 'TvLivestreamsNowAndNext',
  Shows: onlyActiveShows => `Shows-${onlyActiveShows}`,
  PacPageSections: pageId => `PacPageSections-${pageId}`,
  PacLandingPage: 'PacLandingPage',
  PacTopicPage: topicUrn => `PacTopicPage-${topicUrn}`,
  PacShowPage: showUrn => `PacShowPage-${showUrn}`,
  PacPageConfigs: 'PacPageConfigs',
  MediaSectionWithShow: id => `MediaSectionWithShow-${id}`,
  MediaSection: id => `MediaSection-${id}`,
  ShowSection: id => `ShowSection-${id}`,
  SectionDetail: id => `SectionDetail-${id}`,
  TvProgramGuide: (date, provider) => `TvProgramGuide-${date}-${provider}`,
  TvProgramGuideChannel: (date, channelId) => `TvProgramGuide-${date}-${channelId}`
};
import { useEffect, useState } from 'react';
import { isBrowser } from '../utils/ssr-helper';
const useMeasure = ref => {
  const [size, setSize] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    scrollWidth: 0
  });
  const [observer] = useState(() => isBrowser ? new ResizeObserver(([entry]) => {
    setSize({
      left: entry.contentRect.left,
      top: entry.contentRect.top,
      width: entry.contentRect.width,
      height: entry.contentRect.height,
      scrollWidth: entry.target.scrollWidth
    });
  }) : {
    observe: () => {},
    disconnect: () => {}
  });
  useEffect(() => {
    ref.current && observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref, observer]);
  return size;
};
export { useMeasure };
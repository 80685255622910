import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAxios } from './use-axios';
import { loadGeneralInformationMessage } from '../../../play-core/api/load-general-information-message';
import { queryKeys } from '../config/use-query';
const loadDelay = 5 * 1000;
const staleTime = 5 * 60 * 1000;
export const useGeneralInformationMessage = () => {
  const axios = useAxios();
  const {
    data: message,
    refetch
  } = useQuery({
    queryKey: [queryKeys.GeneralInformationMessage, axios],
    queryFn: () => loadGeneralInformationMessage(axios),
    enabled: false,
    staleTime
  });
  useEffect(() => {
    setTimeout(() => refetch(), loadDelay);
  }, [refetch]);
  return message;
};
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "@remix-run/react";
import { toMediaType } from '../../../../../play-core/models/MediaType';
import { toDurationFilter } from '../../../../../play-core/models/search/DurationFilter';
import { useAnalyticsDispatch } from '../../../analytics/AnalyticsContext';
import { usePath, useRoutes, useVideoConfig } from '../../../config/VideoAppContext';
import { findRoute } from '../../../models/PlayRoute';
import { useSearchDispatch, useSearchState } from '../SearchContext';
import { searchRoute as searchAppRoute } from '../SearchPage.route';
export const useSearchUrlSync = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = useSearchState();
  const dispatch = useSearchDispatch();
  const {
    searchPath
  } = usePath();
  const [appInitialized, setAppInitialized] = useState(false);
  const analytics = useAnalyticsDispatch();
  const {
    i18n
  } = useTranslation();
  const config = useVideoConfig();
  const routes = useRoutes();

  // Set the initial query when the app starts with an URL pointing to the
  // search page.
  useEffect(() => {
    if (!appInitialized) {
      setAppInitialized(true);
      if (isOnSearchPage(location, searchPath)) {
        const queryState = toQueryState(location.search);
        queryState.query.mediaType = queryState.query.mediaType?.toUpperCase();
        dispatch({
          type: 'set-query-state',
          state: queryState
        });
        const referrerPage = state.previousPage ? findRoute(state.previousPage, routes)?.pageId : undefined;
        if (referrerPage === undefined) {
          analytics({
            type: 'perform-search',
            referrerPage: searchAppRoute(i18n).pageId,
            searchTerm: queryState.query.searchTerm
          });
        }
      }
    }
  }, [routes, appInitialized, location.search, searchPath, location, dispatch, state.previousPage, i18n, config, analytics]);

  // Update the current URL when we're on the search page and the user changes
  // the query or sets a filter.
  useEffect(() => {
    if (appInitialized && isOnSearchPage(location, searchPath)) {
      const currentUrl = location.pathname + location.search;
      const newUrl = `${searchPath}?${toQueryString({
        query: state.query,
        showAll: state.showAll,
        hideShowSearch: state.hideShowSearch
      })}`;
      if (state.searchOpen && currentUrl !== newUrl) {
        navigate(newUrl, {
          replace: true
        });
      }
    }
  }, [appInitialized, state.query, state.showAll, state.hideShowSearch, state.searchOpen, navigate, searchPath, location]);

  // Keep the flag about whether or not we're currently on the search page up
  // to date, depending on the current location.
  useEffect(() => {
    const searchPageOpen = isOnSearchPage(location, searchPath);
    if (searchPageOpen !== state.searchPageOpen) {
      if (!state.searchPageOpen) {
        dispatch({
          type: 'set-query-state',
          state: toQueryState(location.search)
        });
      }
      dispatch({
        type: 'set-search-page-open',
        isOpen: searchPageOpen
      });
    }
  }, [location, state.searchPageOpen, dispatch, searchPath]);

  // Navigate to the search page when we're not on the search page yet and the
  // user entered more than 2 characters, pressed enter or the 'show all' button.
  useEffect(() => {
    const searchPageOpen = isOnSearchPage(location, searchPath);
    if (!searchPageOpen && !state.searchPageOpen && (state.query.searchTerm.length > 2 || state.showAll || state.query.includeAggregations)) {
      const currentUrl = location.pathname + location.search;
      dispatch({
        type: 'set-previous-page',
        previousPage: currentUrl
      });
      const newUrl = `${searchPath}?${toQueryString({
        query: state.query,
        showAll: state.showAll,
        hideShowSearch: state.hideShowSearch
      })}`;
      navigate(newUrl);
    }
  }, [location, navigate, state.query, state.searchPageOpen, state.showAll, state.hideShowSearch, searchPath, dispatch]);
};
const isOnSearchPage = (location, searchRoute) => location.pathname.startsWith(searchRoute);
const separator = ',';
const parameterNames = {
  query: 'query',
  shows: 'shows',
  topics: 'topics',
  from: 'from',
  to: 'to',
  showAll: 'showAll',
  mediaType: 'mediaType',
  duration: 'duration',
  properties: 'properties',
  hideShowSearch: 'hideShowSearch',
  order: 'order'
};
export const toQueryString = state => {
  const params = new URLSearchParams();
  const {
    query
  } = state;
  params.append(parameterNames.query, query.searchTerm);
  state.showAll && params.append(parameterNames.showAll, 'true');
  state.hideShowSearch && params.append(parameterNames.hideShowSearch, 'true');
  addArrayValue(params, parameterNames.shows, query.shows);
  addArrayValue(params, parameterNames.topics, query.topics);
  query.from && params.append(parameterNames.from, query.from);
  query.to && params.append(parameterNames.to, query.to);
  query.mediaType && params.append(parameterNames.mediaType, query.mediaType.toLowerCase());
  query.duration && params.append(parameterNames.duration, query.duration);
  query.order && params.append(parameterNames.order, query.order);
  if (query.mediaProperties) {
    addArrayValue(params, parameterNames.properties, query.mediaProperties);
  }
  return params.toString();
};
export const toQueryState = queryString => {
  const params = new URLSearchParams(queryString);
  const searchTerm = params.get(parameterNames.query) || '';
  const showAll = params.get(parameterNames.showAll) === 'true';
  const hideShowSearch = params.get(parameterNames.hideShowSearch) === 'true';
  const shows = getArrayValue(params, parameterNames.shows);
  const topics = getArrayValue(params, parameterNames.topics);
  const includeAggregations = topics.length > 0 || shows.length > 0;
  const from = params.get(parameterNames.from) || '';
  const to = params.get(parameterNames.to) || '';
  const mediaType = toMediaType(params.get(parameterNames.mediaType)?.toUpperCase());
  const duration = toDurationFilter(params.get(parameterNames.duration));
  const order = params.get(parameterNames.order) === 'desc' ? 'desc' : undefined;
  const mediaProperties = getArrayValue(params, parameterNames.properties);
  return {
    query: {
      searchTerm,
      shows,
      topics,
      includeAggregations,
      from,
      to,
      mediaType,
      duration,
      order,
      mediaProperties
    },
    showAll,
    hideShowSearch
  };
};
const addArrayValue = (params, paramName, values) => {
  if (values && values.length > 0) {
    params.append(paramName, `${values?.join(separator)}`);
  }
};
const getArrayValue = (params, paramName) => {
  if (params.has(paramName)) {
    const value = params.get(paramName) || '';
    return value.split(separator);
  }
  return [];
};
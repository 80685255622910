import { styled, css, keyframes } from 'styled-components';
import { Colors, hexToRGB } from '../../../../../play-core/styles/Colors';
import { Viewports } from '../../../../../play-core/styles/Viewports';
const horizontalImagePercentage = 38;
export const Layout = styled.div.withConfig({
  displayName: "ImageOverlaystyles__Layout",
  componentId: "sc-5vxiwf-0"
})(["position:absolute;pointer-events:none;overflow:hidden;width:100%;top:0;left:0;padding-top:", ";", ""], ({
  aspectRatio
}) => `${100 / aspectRatio}%`, ({
  horizontalOnMobile,
  aspectRatio
}) => horizontalOnMobile && css(["", "{width:", "%;right:auto;padding-top:", "%;}"], Viewports.for('small'), horizontalImagePercentage, horizontalImagePercentage / aspectRatio));
const fadeIn = keyframes(["0%{opacity:0;}100%{opacity:1;}"]);
export const ImageShadow = styled.div.withConfig({
  displayName: "ImageOverlaystyles__ImageShadow",
  componentId: "sc-5vxiwf-1"
})(["position:absolute;left:0;top:0;width:100%;height:100%;background:linear-gradient(to bottom,", ",transparent 48%);animation:", " 0.2s ease-in;"], hexToRGB(Colors.GREY_1a1a1a, 0.5), fadeIn);
export const Overlay = styled.div.withConfig({
  displayName: "ImageOverlaystyles__Overlay",
  componentId: "sc-5vxiwf-2"
})(["display:flex;align-items:flex-start;position:absolute;top:0;left:0;bottom:0;right:0;"]);
export const ActionSection = styled.div.withConfig({
  displayName: "ImageOverlaystyles__ActionSection",
  componentId: "sc-5vxiwf-3"
})(["display:flex;margin-left:auto;> *{margin-top:2px;margin-right:2px;}"]);
export const MediaTypeOverlay = styled.div.withConfig({
  displayName: "ImageOverlaystyles__MediaTypeOverlay",
  componentId: "sc-5vxiwf-4"
})(["pointer-events:none;position:absolute;color:", ";left:0;top:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;"], Colors.WHITE);
export const MetaInformationContainer = styled.div.withConfig({
  displayName: "ImageOverlaystyles__MetaInformationContainer",
  componentId: "sc-5vxiwf-5"
})(["position:absolute;display:grid;grid-auto-flow:column;grid-gap:4px;bottom:8px;right:8px;"]);
import { useTranslation } from 'react-i18next';
import { usePath, useVideoConfig } from '../../../config/VideoAppContext';
import { useLoginState } from '../../../providers/DataProvider/DataContext';
export const useAppendMenuEntries = () => {
  const {
    t
  } = useTranslation();
  const {
    settingsPath
  } = usePath();
  const {
    isLoggedIn
  } = useLoginState();
  const {
    personalizationSupported
  } = useVideoConfig();
  const showSettings = personalizationSupported && !isLoggedIn;
  if (showSettings) {
    return [{
      type: 'Divider'
    }, {
      type: 'Link',
      title: t('global.settings'),
      url: settingsPath,
      icon: 'Settings'
    }];
  }
  return [];
};
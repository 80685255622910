import { useLoginState } from '../../../providers/DataProvider/DataContext';
import { useHeaderState } from '../HeaderContext';
import { useAppendMenuEntries } from './use-append-menu-entries';
import { usePrependMenuEntries } from './use-prepend-menu-entries';
export const useMenuEntries = () => {
  const {
    isLoggedIn
  } = useLoginState();
  const {
    config: {
      menuEntries = [],
      loginEntries = []
    }
  } = useHeaderState();
  const prependingMenuEntries = usePrependMenuEntries();
  const appendingMenuEntries = useAppendMenuEntries();
  const enrichedMenuEntries = [...prependingMenuEntries, ...menuEntries, ...appendingMenuEntries];
  const loggedInMenuEntries = isLoggedIn ? [...loginEntries, {
    type: 'Divider'
  }, ...enrichedMenuEntries] : [];
  return {
    menuEntries: isLoggedIn ? [] : enrichedMenuEntries,
    loggedInMenuEntries
  };
};
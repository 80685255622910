import { useEffect, useState } from 'react';
export const useA11yOverlayDelay = (isFocused, isHovering) => {
  const [delayedIsFocused, setDelayedFocused] = useState(isFocused);
  const [, setFocusTimeoutCancel] = useState();
  const [delayedIsHovered, setDelayedHovered] = useState(isHovering);
  const [, setHoverTimeoutCancel] = useState();
  useEffect(() => {
    if (isFocused) {
      setDelayedFocused(true);
      setFocusTimeoutCancel(value => {
        window.clearTimeout(value);
        return value;
      });
    } else {
      const timeout = window.setTimeout(() => setDelayedFocused(false), 200);
      setFocusTimeoutCancel(timeout);
      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [isFocused]);
  useEffect(() => {
    if (isHovering) {
      setDelayedHovered(true);
      setHoverTimeoutCancel(value => {
        window.clearTimeout(value);
        return value;
      });
    } else {
      const timeout = window.setTimeout(() => setDelayedHovered(false), 200);
      setHoverTimeoutCancel(timeout);
      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [isHovering]);
  return {
    delayedIsFocused,
    delayedIsHovered
  };
};
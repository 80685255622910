import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { zIndex } from '../../../../../play-core/styles/z-index';
import { PlayContainer, ContentContainer } from '../../PlayContainer/PlayContainer';
import { headerShadow } from '../Header.globals';
export const secondLevelNaviHeight = 54;
export const Title = styled.h1.withConfig({
  displayName: "SecondLevelNavigationstyles__Title",
  componentId: "sc-1aqedv2-0"
})(["", ";color:", ";margin:0;padding:6px 0 14px;"], Fonts.bold(36), Colors.WHITE);
export const TitleContainer = styled(PlayContainer).withConfig({
  displayName: "SecondLevelNavigationstyles__TitleContainer",
  componentId: "sc-1aqedv2-1"
})(["background-color:", ";z-index:", ";"], Colors.DARK_202020, zIndex.header);
export const NavigationContainer = styled(PlayContainer).withConfig({
  displayName: "SecondLevelNavigationstyles__NavigationContainer",
  componentId: "sc-1aqedv2-2"
})(["background-color:", ";top:0;position:sticky;z-index:", ";", ";", "{overflow:hidden;}"], Colors.DARK_202020, zIndex.header, headerShadow, ContentContainer);
import { toQueryString } from './request-url-helper';
export const scaleImageSource = (imageSource, width, imageFormat = 'webp', modernImagesBaseUrl) => {
  if (modernImagesBaseUrl?.trim()) {
    const queryString = toQueryString({
      imageUrl: imageSource,
      format: imageFormat,
      width
    });
    return `${modernImagesBaseUrl}${queryString}`;
  }
  return `${imageSource}/scale/width/${width}`;
};
import { useEffect } from 'react';
import { usePrivacyDispatch } from './PrivacyProvider/PrivacyContext';
import { usePersonalizationConsentTracking } from './use-personalization-consent-tracking';

/**
 * uses publish subscribe pattern from index.html
 * normally we would use an event listener here, but if the consent is given and the use make a hard reload the ucEvent is not received at this place.
 */
export const useUcEvent = () => {
  const dispatchPolicy = usePrivacyDispatch();
  const trackPersonalization = usePersonalizationConsentTracking();
  useEffect(() => {
    const onUcEvent = detail => {
      dispatchPolicy({
        type: 'set-consent',
        detail
      });
    };
    const unsubscribe = window.__PubSub_PLAY__.subscribe('ucEvent', onUcEvent);
    return () => {
      unsubscribe();
    };
  }, [dispatchPolicy]);
  useEffect(() => {
    const onUcEvent = detail => {
      trackPersonalization(detail.Personalisierung);
    };
    const unsubscribe = window.__PubSub_PLAY__.subscribe('ucEvent', onUcEvent);
    return () => {
      unsubscribe();
    };
  }, [trackPersonalization]);
};
import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-generalInformationMessages';
const defaultValue = {
  closed: []
};
const getClosedMessageIds = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem().closed;
};
const addClosedMessageId = messageId => {
  const {
    setItem,
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  const {
    closed
  } = getItem();
  if (!closed.includes(messageId)) {
    closed.push(messageId);
  }
  return setItem({
    closed
  });
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem(defaultValue);
};
export const generalInformationStorage = {
  getClosedMessageIds,
  addClosedMessageId,
  reset
};
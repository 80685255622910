import { useEffect, useState } from 'react';
import { useVideoConfig } from './VideoAppContext';
import { useDeviceState } from '../providers/DeviceProvider/DeviceContext';
import { useViewportState } from '../providers/ViewportProvider/ViewportContext';
export const useFeedbackUrl = () => {
  const {
    feedbackUrlMobile,
    feedbackUrlDesktop
  } = useVideoConfig();
  const [feedbackUrl, setFeedbackUrl] = useState(feedbackUrlDesktop);
  const {
    isTouchDevice
  } = useDeviceState();
  const {
    small
  } = useViewportState();
  const isMobile = isTouchDevice && small;
  useEffect(() => {
    setFeedbackUrl(isMobile ? feedbackUrlMobile : feedbackUrlDesktop);
  }, [feedbackUrlDesktop, feedbackUrlMobile, isMobile]);
  return feedbackUrl;
};
import { styled, css, keyframes } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { Shadows } from '../../../../../play-core/styles/Shadows';
import { Viewports } from '../../../../../play-core/styles/Viewports';
export const FormContainer = styled.div.withConfig({
  displayName: "SearchFormstyles__FormContainer",
  componentId: "sc-1slrddp-0"
})(["display:flex;justify-content:space-around;align-items:center;flex-grow:1;position:relative;"]);
export const FormWrapper = styled.div.withConfig({
  displayName: "SearchFormstyles__FormWrapper",
  componentId: "sc-1slrddp-1"
})(["position:relative;width:100%;", "{width:", ";margin-right:", ";}"], Viewports.for('small'), props => props.fullWidth ? '100%' : 'calc(100% - 50px)', props => props.fullWidth ? '8px' : '0');
export const StyledSearchForm = styled.form.withConfig({
  displayName: "SearchFormstyles__StyledSearchForm",
  componentId: "sc-1slrddp-2"
})(["width:100%;display:flex;flex-direction:column;align-items:flex-end;position:relative;"]);
export const SearchInput = styled.input.withConfig({
  displayName: "SearchFormstyles__SearchInput",
  componentId: "sc-1slrddp-3"
})(["width:100%;border:0;color:", ";background-color:", ";outline:0;&:focus{outline:0;}", "{", ";padding-top:10px;padding-bottom:10px;padding-left:10px;border-radius:0;background-color:", ";", ";color:", ";border:1px solid ", ";}", "{", ";padding:14px 20px 14px 55px;width:", ";transition:width 100ms ease-in;border-radius:2px;&::placeholder{color:", ";transition:color 350ms ease-in;}}"], Colors.GREY_222222, Colors.WHITE, Viewports.for('small'), Fonts.regular(16), Colors.DARK_202020, Shadows.text(), Colors.WHITE, Colors.GREY_454545, Viewports.for('mediumUp'), Shadows.applyDouble(2), props => props.isOpen ? '100%' : '0%', props => props.isOpen ? Colors.WHITE : 'transparent');
export const SearchLabel = styled.label.withConfig({
  displayName: "SearchFormstyles__SearchLabel",
  componentId: "sc-1slrddp-4"
})(["display:none;"]);
export const SearchIcon = styled.div.withConfig({
  displayName: "SearchFormstyles__SearchIcon",
  componentId: "sc-1slrddp-5"
})(["position:absolute;left:0;top:0;pointer-events:none;height:100%;display:flex;align-items:center;font-size:24px;color:", ";padding-left:15px;opacity:", ";", " ", "{display:none;}"], Colors.GREY_969696, props => props.isOpen ? 1 : 0, ({
  isOpen
}) => isOpen && css(["animation:", " 250ms ease-out;"], delayedFadeIn), Viewports.for('small'));
export const ResetButton = styled.button.withConfig({
  displayName: "SearchFormstyles__ResetButton",
  componentId: "sc-1slrddp-6"
})(["position:absolute;right:0;top:0;height:100%;font-size:24px;padding-right:10px;line-height:1;align-items:center;display:", ";color:", ";&:hover{color:", ";}"], props => props.isVisible ? 'flex' : 'none', Colors.GREY_969696, Colors.GREY_aaaaaa);
export const ShowAllButton = styled.button.withConfig({
  displayName: "SearchFormstyles__ShowAllButton",
  componentId: "sc-1slrddp-7"
})(["position:absolute;right:0;top:0;height:calc(100% - 2px);margin:1px;", ";padding:0 12px;border:3px solid transparent;background-color:", ";white-space:nowrap;line-height:1;color:", ";&:hover{color:", ";}", "{opacity:", ";", "}", "{background-color:", ";}"], Fonts.regular(14), Colors.WHITE, Colors.GREY_969696, Colors.GREY_aaaaaa, Viewports.for('mediumUp'), props => props.isOpen ? 1 : 0, ({
  isOpen
}) => isOpen && css(["animation:", " 250ms ease-out;"], delayedFadeIn), Viewports.for('small'), Colors.DARK_202020);
export const Filter = styled.button.withConfig({
  displayName: "SearchFormstyles__Filter",
  componentId: "sc-1slrddp-8"
})(["padding:5px;color:", ";", "{display:none;}&:active{background-color:", ";}"], Colors.GREY_d2d2d2, Viewports.for('mediumUp'), Colors.GREY_222222);
export const delayedFadeIn = keyframes(["0%{opacity:0;}80%{opacity:0.1;}100%{opacity:1;}"]);
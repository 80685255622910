import { Fragment } from 'react';

import { useNonce } from '@app/providers/nonce.provider';
import { isServer } from '@play-now/core/utils/ssr-helper';
import { useTCLoader } from '@play-now/video/analytics/use-tc-loader';
import { useUdpPageViewTracking } from '@play-now/video/analytics/use-udp-page-view-tracking';
import { AudioDescriptionLink, CONTENT_ID, SkipLink } from '@play-now/video/components/AccessibleLink/AccessibleLink';
import { AppPromotionBanner } from '@play-now/video/components/AppBanner/AppPromotionBanner';
import { FeatureSurvey, isFeatureSurveyActive } from '@play-now/video/components/FeatureSurvey/FeatureSurvey';
import { DefaultFooter } from '@play-now/video/components/Footer/DefaultFooter';
import { DefaultHeader } from '@play-now/video/components/Header/DefaultHeader';
import { TopicHeader } from '@play-now/video/components/Header/TopicHeader';
import { GlobalLoadingBar } from '@play-now/video/components/LoadingBar/GlobalLoadingBar';
import { useLoadingBar } from '@play-now/video/components/LoadingBar/LoadingBarProvider';
import { PlayGeneralInformationBanner } from '@play-now/video/components/PlayGeneralInformationBanner/PlayGeneralInformationBanner';
import { PlayLayoutContainer } from '@play-now/video/components/PlayLayoutContainer/PlayLayoutContainer';
import { ScrollTop } from '@play-now/video/components/ScrollTop/ScrollTop';
import { useErrorState, useInitialData, useRoutes, useVideoConfig } from '@play-now/video/config/VideoAppContext';
import { PageId } from '@play-now/video/models/PlayRoute';
import { ErrorBoundary } from '@play-now/video/pages/Error/ErrorBoundary';
import { UnknownError } from '@play-now/video/pages/Error/UnknownError';
import { PrivacyBanner } from '@play-now/video/privacy/PrivacyBanner/PrivacyBanner';
import { UiConfigProvider } from '@play-now/video/providers/UiConfigProvider/UiConfigProvider';
import { defaultLayout, PlayVideoRoute, VideoInitialData } from '@play-now/video/routes/Routes';
import { useNavigation } from '@remix-run/react';

// this is a fallback route in case no route is found for the current pageId
const errorRoute: PlayVideoRoute = {
  pageId: 'unknownError',
  pageType: 'landingpage',
  pageSection: 'tv',
  path: '*',
};

export const PlayLayout = ({ children, pageId }: { children: React.ReactNode; pageId: PageId }) => {
  const navigation = useNavigation();
  useLoadingBar(navigation.state === 'loading');

  const route = useRoutes().find((currentRoute) => currentRoute.pageId === pageId) ?? errorRoute;
  const initialData = useInitialData();
  const error = useErrorState();
  const showError = isServer && !!error?.serverError;
  const { surveys } = useVideoConfig();
  const pageFeatureSurvey = surveys[pageId];
  useUdpPageViewTracking(route, initialData);
  const nonce = useNonce();
  useTCLoader({ route, nonce });

  const content = (
    <Fragment>
      {showError ? (
        <UnknownError />
      ) : (
        <ErrorBoundary errorComponent={<UnknownError />}>
          {children}
          {pageFeatureSurvey && <FeatureSurvey {...pageFeatureSurvey} />}
          {route.showScrollTop && !isFeatureSurveyActive(pageFeatureSurvey) && <ScrollTop />}
        </ErrorBoundary>
      )}
    </Fragment>
  );

  return (
    <UiConfigProvider
      providerSettings={{
        type: 'PageId',
        value: pageId,
      }}
    >
      <PrivacyBanner suppressBanner={route.suppressBanner ?? false} />
      <GlobalLoadingBar />
      <Layout route={route} initialData={initialData}>
        {content}
      </Layout>
      <PlayGeneralInformationBanner />
    </UiConfigProvider>
  );
};

const Layout = ({
  children,
  route: initialRoute,
  initialData,
}: {
  children: React.ReactNode;
  route: PlayVideoRoute;
  initialData: VideoInitialData;
}) => {
  const {
    header: { headerType },
  } = useVideoConfig();
  const route = {
    ...initialRoute,
    layout: { ...defaultLayout, ...(initialRoute.layout || {}) },
  };
  const { layout } = route;

  const header = headerType === 'Topic' ? <TopicHeader route={route} /> : <DefaultHeader route={route} initialData={initialData} />;

  return (
    <PlayLayoutContainer>
      <AudioDescriptionLink />
      <SkipLink />
      {route.pageId === 'home' && <AppPromotionBanner />}
      {layout.header !== 'none' && header}
      <main id={CONTENT_ID}>{children}</main>
      {layout.footer !== 'none' && <DefaultFooter />}
    </PlayLayoutContainer>
  );
};
